import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { getLanguage } from "../../util/localStorage";

import slantedReport from "../../media/img/slanted-report.png";
import swiftLogo from "../../media/img/swift-finance-logo.png";
import ClientLogos from "../layouts/ClientLogos";
import { track } from "../../actions/trackingActions";

import OverviewVideo from "../../media/video/overview-swift.mp4";

import TestimonialCards from "../layouts/TestimonialCards";
import Video from "../layouts/Video";

import AOS from "aos";
import "aos/dist/aos.css";

const Home = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({ duration: 800 });
    AOS.refresh();

    window.onscroll = function () {
      window.onscroll = null;
      dispatch(track(2));
    };
  }, []);

  let languageStoredInLocalStorage = getLanguage();

  let content = {
    english: {
      hero: {
        title: "A centralized solution that modernizes finance departments and facilitates the implementation of best practices",
      },
      generalDescription: "The modern finance department, equipped for efficiency with ease.",
      swiftForestOverview: [
        "No more manual errors - each amount remains attached to its label",
        "Define your models and formats just once",
        "Dynamic and flexible reporting",
        "(double click to break down your periods and your data)",
        "Your data manager: direct access to your GL accounts",
        "Create your own personalized totals to facilitate alternate groupings",
        "Relative reallocations: the flexibility to adjust forecasts in one click",
      ],
      overviewVideoSection: {
        title: "The art of doing so much more in a lot less time",
        listElements: [
          "Create a report directly from your GL",
          "Easily slice and dice and data element in your report to access more detail",
          "Simple budgets & forecasts ... even with several legal entities",
          "One-click budget and forecast updates",
          "And much more!",
        ],
      },
    },
    french: {
      hero: {
        title: "Une solution centralisée qui modernise les départements de finance et facilite l’implémentation des meilleures pratiques",
      },
      generalDescription: "Le département de finance moderne, outillé pour l’efficacité en toute facilité.",
      swiftForestOverview: [
        "Fini les erreurs manuelles - chaque montant demeure attaché à son libellé",
        "Définissez vos modèles et formats une seule fois",
        "Création de rapports dynamiques et flexibles",
        "(double clic pour ventiler vos périodes et vos données)",
        "Votre gestionnaire de données: un accès direct à vos comptes de GL",
        "Certains rapports vous demandent de regrouper plusieurs comptes dans la même rubrique?",
        "Créez vos propres comptes personnalisés pour faciliter leur regroupement.",
        "Réallocations relatives: la flexbilité d'ajuster ses prévisions en un clic",
      ],
      overviewVideoSection: {
        title: "L’art de faire tellement plus en bien moins de temps",
        listElements: [
          "Créez un rapport directement depuis votre GL",
          " Manipulez et ventilez facilement vos rapports (drag and drop)",
          "Budgets & prévisions simples... même avec plusieurs entités légales",
          "Mises à jour des prévisions budgétaires en un clic",
          "Et bien plus encore!",
        ],
      },
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div>
      {/* --hero section */}
      <div className="hero">
        <h2>{content.hero.title}</h2>
        {/* <h2>
          Votre équipe de finance est-elle outillée pour fonctionner ou outillée <br />« pour s’arranger que ça fonctionne »?
        </h2> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          preserveAspectRatio="none"
          viewBox="0 0 1680 40"
          className="position-absolute width-full z-1"
          style={{ bottom: " -1px" }}
        >
          <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
        </svg>
      </div>

      <div>
        {/* --section 2 */}
        <div className="general-description">
          <div className="">
            <img src={swiftLogo} data-aos="fade-right" className="swift-logo" alt="" />
          </div>
          <h2 data-aos="fade-right">
            {content.generalDescription}
            {/* Le département de finance moderne,
            <br /> outillé pour l’efficacité en toute facilité. */}
          </h2>
        </div>

        {/* section */}

        <div className="swift-forest-overview">
          <div className="visual-container">
            <img src={slantedReport} />

            <div data-aos="fade-left" data-aos-easing="ease-in-back" data-aos-delay="500" className="floating-text ft-1">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[0]}
            </div>
            <div data-aos="fade-left" data-aos-delay="1000" className="floating-text ft-2">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[1]}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-3">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[2]}
              <br />
              {/* <span>{content.swiftForestOverview[3]}</span> */}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-4">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[4]}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-5">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[5]}
              {/* <br />
              <br /> {content.swiftForestOverview[6]} */}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-6">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[6]}
            </div>
          </div>
        </div>

        {/* Video overview section */}
        <div className="overview-video-section">
          <div data-aos="fade-right" className="">
            <h2>{content.overviewVideoSection.title}</h2>
            <ul>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[0]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[1]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[2]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[3]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[4]}
              </li>
            </ul>
          </div>
          <Video videoSource={OverviewVideo} FlagPlay={10} Flag10Sec={11} Flag80Percent={12} videoName={"vid1"} />
        </div>
      </div>
    </div>
  );
};

export default connect()(Home);
