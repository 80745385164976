import React, { useEffect } from "react";
import { connect } from "react-redux";
import swiftLogo from "../../media/img/swift-finance-logo.png";
import { Link } from "react-router-dom";
import clientLogo from "../../media/img/client-logos/richter.jpg";
import clientLogo2 from "../../media/img/client-logos/triton.png";
import clientLogo3 from "../../media/img/client-logos/univesta.png";
import { track } from "../../actions/trackingActions";

class Page3 extends React.Component {
  state = {
    blockNumber: 1,
  };

  nextBlock = () => {
    this.setState((prevState) => ({ blockNumber: prevState.blockNumber + 1 }));
    this.props.dispatch(track(7));
  };

  previousBlock = () => {
    this.setState((prevState) => ({ blockNumber: prevState.blockNumber - 1 }));
  };

  render() {
    let language = "English";
    localStorage.setItem("language", language);

    function storeLanguageInLocalStorage(language) {
      localStorage.setItem("language", language);
    }

    let languageStoredInLocalStorage = localStorage.getItem("language");

    let content = {
      english: {
        overviewColumn: ["Company", "Industry", "Department", "Process", "Database", "One of the major Canadian accounting firms"],
        successStory1: {
          title: "Hassle-free ERP transition",
          description:
            "Swift Finance enables one of the major Canadian accounting firms to smoothly transition their month-end and budget planning process to a new ERP",
          subheadings: ["client", "problem", "challenges", "solution"],
          p: [
            "A private company based in Montreal with more than 450 professionals and offices in Montreal, Toronto and Chicago, is the 9th largest accounting consulting firm in Canada.",
            "The Finance department had already been using the Swift Finance solution with data from the Microsoft Dynamics GP ERP for two years, when senior management decided to make the transition to a new ERP, Deltek Maconomy, specializing in project management and which includes already a BI offer for financial reporting.",
            "At that time, Swift Finance did not yet have a connector for Deltek Maconomy, while senior management pushed for its finance department to use the BI reporting solution that is included with the new ERP. People in the finance department wanted to continue using Swift Finance because it allowed them to complete their month-end and budget process much faster than traditional BI solutions.",
            "Our flexible infrastructure allowed us to quickly implement a connector at Deltek Maconomy while our consulting group ensured everything was in place for the transition.",
          ],
          columnContent: ["Accounting", "Month-end", "Budget", "Forecasts"],
        },
        successStory2: {
          title: "Simplification of the budget process",
          description:
            "Swift Finance enables Triton Digital's Finance group to complete their budget process 200% faster, with fewer resources and fewer errors.",
          subheadings: ["client", "problem", "challenges", "solution"],
          p: [
            "TRITON DIGITAL - A private company based in California, USA, with offices in Canada, England, Spain, South Africa and Singapore. Triton Digital is a world leader in online advertising placement for internet radio networks.",
            "The budget process was decentralized and relied on in-house Excel templates that were emailed to budget managers. They entered their data at a very detailed level, then returned the Excel files which were then loaded into Microsoft Dynamics NAV in order to be able to visualize the new consolidated budget. All of these manual operations were time consuming, consumed many resources, and generated a lot of errors.",
            "Finance users wanted to continue using Microsoft Excel, but without having to deal with the manual aspects of data synchronization, while significantly reducing the time required to cycle through each version of the budget.",
            "The budget process is now entirely done outside of the ERP, being defined, validated and consolidated directly from Microsoft Excel, with the use of the advanced allocations and postings features of the Swift Finance solution. This significantly reduces the time required to arrive at the final version of the budget, by following a new, simpler iterative process that reduces errors.",
          ],
          columnContent: ["Online advertising", "Month-end", "Budget", "Forecast"],
        },
        successStory3: {
          title: "Do a lot more with less",
          description:
            "Swift Finance enables Univesta's Finance group to close their month-end on time with our cutting-edge features and efficient automation.",
          subheadings: ["client", "problem", "challenges", "solution"],
          p: [
            "UNIVESTA - A private company based in Montreal, Canada, which is one of the leaders in Quebec for the provision of personalized financial products and personal insurance services.",
            "As the business grew, with each successive acquisition, the small finance department found it increasingly difficult to cope with the increased workload and had to resign itself to working longer hours. every day in order to close the month.",
            "The company initially believed it could hire more staff to help produce the specialized reports used to calculate brokerage commissions. However, there were two problems with this approach; 1) the reports required a lot of manual operations and the addition of human resources would have been temporarily beneficial given the growth of the business, and 2) the in-house ERP that was being used was too complex and inflexible to be used by non-technical resources.",
            "We have adapted our Swift Finance solution to the particularities of the management field of insurance brokers operating in a very personalized environment. Our flexible infrastructure allowed us to quickly implement a connector to the in-house ERP, while our consulting group used the automation features of our solution to produce a custom reporting application. Rather than increasing the payroll burden, our solution allowed existing resources to do much more than before.",
          ],
          columnContent: ["Brokerage", "Month-end", "tailored"],
        },
      },
      french: {
        overviewColumn: ["Entreprise", "Industrie", "Département", "Processus", "Base de données", "Un des grands cabinets comptables canadien"],
        successStory1: {
          title: "Transition d’ERP sans tracas",
          description:
            "Swift Finance permet au groupe de Finance de Richter LLP d’effectuer une transition en douceur de leur processus de fin de mois et planification budgétaire vers un nouvel ERP",
          subheadings: ["client", "problem", "challenges", "solution"],
          p: [
            "Une entreprise privée basée à Montréal avec plus de 450 professionnels et des bureaux à Montréal, Toronto et Chicago, est la 9ième plus grande entreprise de services-conseils en comptabilité au Canada.",
            "Le département de Finance utilisait déjà depuis deux ans la solution Swift Finance avec les données de leur ERP, quand la haute direction pris la décision de faire la transition vers un nouvel ERP, spécialisé sur la gestion par projets et qui inclut déjà une offre BI de reporting financier.",
            "A cette époque, Swift Finance ne disposait pas encore de connecteur pour le nouvel ERP, tandis que la haute direction poussait pour que le département de finance utilise la solution BI de reporting qui est incluse avec le nouvel ERP. Les usagers du département de finance, quant à eux, voulaient continuer à utiliser Swift Finance, car il leur permettait de compléter leur processus de fin de mois et de budget beaucoup plus rapidement que les solutions BI traditionnelles.",
            "Notre infrastructure flexible nous a permis d’implanter rapidement un connecteur pour le nouvel ERP pendant que notre groupe de services-conseils s’assurait que tout serait en place pour la transition.",
          ],
          columnContent: ["Comptabilité", "Fin de mois", "Budget", "Prévisions"],
        },
        successStory2: {
          title: "Simplification du processus budgétaire",
          description:
            "Swift Finance permet au groupe de Finance de Triton Digital de compléter son processus budgétaire 200% plus rapidement, avec moins de ressources et d’erreurs.",
          subheadings: ["client", "problem", "challenges", "solution"],
          p: [
            "TRITON DIGITAL – Une entreprise privée basée en Californie, USA, avec des bureaux au Canada, Angleterre, Espagne, Afrique du sud et Singapour. Triton Digital est un des leaders mondiaux du placement publicitaire en ligne pour les réseaux de radios internet.",
            "Le processus budgétaire était décentralisé et dépendant de modèles Excel faits maison qui étaient envoyés par courriel aux directeurs responsables du budget. Ceux-ci saisissaient leurs données à un niveau très détaillé, puis renvoyaient les fichiers Excel qui étaient par la suite chargés dans Microsoft Dynamics NAV afin de pouvoir visualiser le nouveau budget consolidé. Toutes ces opérations manuelles étaient longues, prenaient le temps de plusieurs ressources et généraient beaucoup d’erreurs.",
            "Les usagers de Finance voulaient continuer à utiliser Microsoft Excel, mais sans avoir à gérer les aspects manuels de synchronisation des données, tout en réduisant significativement le temps nécessaire pour cycler à travers chaque version du budget.",
            "Le processus budgétaire est maintenant entièrement fait en dehors de l’ERP, étant défini, validé et consolidé directement à partir de Microsoft Excel, avec l’utilisation des fonctionnalités avancées d’allocations et d’écritures de la solution Swift Finance. Ceci permet de réduire considérablement le temps nécessaire pour arriver à la version finale du budget, en suivant un nouveau processus itératif plus simple qui réduit les erreurs.",
          ],
          columnContent: ["Publicité en ligne", "Fin de mois", "Budget", "Prévisions"],
        },
        successStory3: {
          title: "Faire beaucoup plus avec moins",
          description:
            "Swift Finance permet au groupe de Finance d’Univesta de fermer leur fin de mois à temps grâce à nos fonctionnalités de pointe et une automatisation efficace.",
          subheadings: ["client", "problem", "challenges", "solution"],
          p: [
            "UNIVESTA – Une entreprise privée basée à Montréal, Canada, qui est un des leaders au Québec pour l’offre de services personnalisés de produits financiers et d’assurances aux particuliers.",
            "Au fur et à mesure que l’entreprise croissait, avec chaque acquisition successive, le petit département de Finance avait de plus en plus de difficultés à prendre en charge l’augmentation de la charge de travail et devait se résigner à effectuer de plus longues heures chaque jour afin de fermer le mois.",
            "L’entreprise pensait initialement pouvoir engager plus de personnel afin d’aider à produire les rapports spécialisés servant au calcul des commissions pour les courtiers. Cependant, il y avait deux problèmes avec cette approche; 1) les rapports demandaient beaucoup d’opérations manuelles et l’ajout de ressources humaines aurait été bénéfique temporairement compte tenu de la croissance de l’entreprise , et 2) le ERP maison qui était utilisé était trop complexe et inflexible pour être utilisé par des ressources non-techniques.",
            "Nous avons adapté notre solution Swift Finance pour les particularités du domaine de la gestion de courtiers en assurance opérant dans un environnement très personnalisé. Notre infrastructure flexible nous a permis d’implanter rapidement un connecteur à l’ERP maison, pendant que notre groupe de services-conseils a utilisé les fonctionnalités d’automatisation de notre solution pour produire une application personnalisée de production de rapports. Plutôt que d’augmenter la charge salariale, notre solution a permis aux ressources existantes de faire beaucoup plus qu’auparavant.",
          ],
          columnContent: ["Courtage", "Fin de mois", "sur mesure"],
        },
      },
    };

    languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

    return (
      <div className="page3">
        <div className="bc-container">
          {this.state.blockNumber > 1 && (
            <div className="arrow arrow-left" onClick={this.previousBlock}>
              <i class="fas fa-arrow-left"></i>
            </div>
          )}

          {this.state.blockNumber < 3 && (
            <div className="arrow arrow-right" onClick={this.nextBlock}>
              <i class="fas fa-arrow-right"></i>
            </div>
          )}

          {/* bloc 1 */}

          {this.state.blockNumber == 1 && (
            <div className="bc-block active">
              <div className="overview-column">
                <div className="text-block">{/* <img src={clientLogo} className="bc-client-logo"></img> */}</div>
                <div className="text-block">
                  <label>{content.overviewColumn[0]}&nbsp;</label>

                  <span>{content.overviewColumn[5]}</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[1]}</label>

                  <span>{content.successStory1.columnContent[0]}</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[2]}</label>

                  <span>Finance</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[3]}</label>

                  <span>{content.successStory1.columnContent[1]}</span>

                  <span>{content.successStory1.columnContent[2]}</span>

                  <span>{content.successStory1.columnContent[3]}</span>
                </div>
                <hr />
                <div className="text-block">
                  <label>ERP</label>

                  <span>Dynamics GP 2009 Deltek Macronomy</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[4]}</label>

                  <span>SQL Server 2012</span>
                </div>
              </div>

              {/* MAIN CONTENT */}

              <div className="main-content">
                <h2>{content.successStory1.title}</h2>
                <p className="text-small">{content.successStory1.description}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[0]}
                </h3>
                <p>{content.successStory1.p[0]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[1]}
                </h3>
                <p>{content.successStory1.p[1]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[2]}
                </h3>
                <p>{content.successStory1.p[2]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[3]}
                </h3>
                <p>{content.successStory1.p[3]}</p>
              </div>
            </div>
          )}

          {/* bloc 2 */}

          {this.state.blockNumber == 2 && (
            <div className="bc-block active">
              <div className="overview-column">
                <div className="text-block">
                  <img src={clientLogo2} className="bc-client-logo"></img>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[0]}</label>

                  <span>Triton Digital</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[1]}</label>

                  <span>{content.successStory2.columnContent[0]}</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[2]}</label>

                  <span>Finance</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[3]}</label>

                  <span>Budget</span>
                </div>
                <hr />
                <div className="text-block">
                  <label>ERP</label>

                  <span>Dynamics NAV 2009</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[4]}</label>

                  <span>SQL Server 2010</span>
                </div>
              </div>

              {/* MAIN CONTENT */}

              <div className="main-content">
                <h2>{content.successStory2.title}</h2>
                <p className="text-small">{content.successStory2.description}</p>

                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[0]}
                </h3>
                <p>{content.successStory2.p[0]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[1]}
                </h3>
                <p>{content.successStory2.p[1]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[2]}
                </h3>
                <p>{content.successStory2.p[2]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[3]}
                </h3>
                <p>{content.successStory2.p[3]}</p>
              </div>
            </div>
          )}

          {/* block 3 */}

          {this.state.blockNumber == 3 && (
            <div className="bc-block active">
              <div className="overview-column">
                <div className="text-block">
                  <img src={clientLogo3} className="bc-client-logo"></img>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[0]}</label>

                  <span>Univesta</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[1]}</label>

                  <span>{content.successStory3.columnContent[0]}</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[2]}</label>

                  <span>Finance</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[3]}</label>

                  <span>{content.successStory3.columnContent[1]}</span>
                </div>
                <hr />
                <div className="text-block">
                  <label>ERP</label>

                  <span>{content.successStory3.columnContent[2]}</span>
                </div>
                <div className="text-block">
                  <label>{content.overviewColumn[4]}</label>

                  <span>SQL Server 2010</span>
                </div>
              </div>

              {/* MAIN CONTENT */}

              <div className="main-content">
                <h2>{content.successStory3.title}</h2>
                <p className="text-small">{content.successStory3.description}</p>

                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[0]}
                </h3>
                <p>{content.successStory3.p[0]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[1]}
                </h3>
                <p>{content.successStory3.p[1]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[2]}
                </h3>
                <p>{content.successStory3.p[2]}</p>
                <h3>
                  <i class="fas fa-caret-right"></i> {content.successStory1.subheadings[3]}
                </h3>
                <p>{content.successStory3.p[3]}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(Page3);
