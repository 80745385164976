import React, { useState } from "react";

import { NavLink } from "react-router-dom";

const Header = () => {
  const [navShowing, toggleNav] = useState(false);

  const handleToggleNav = () => {
    toggleNav(!navShowing);
  };
  const closeNav = () => {
    toggleNav(!navShowing);
  };
  return (
    <header>
      <button className="hamburger" onClick={handleToggleNav}>
        <i className="fas fa-bars" />
      </button>
      <NavLink to="/" className="logo-header" onClick={closeNav}></NavLink>
    </header>
  );
};

export default Header;
