import React, { useState, Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Header from "./Header";
import Header2 from "./Header2";

class HeaderRouter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let path = this.props.location.pathname.slice(1);
    if (path === "" || path === "contact" || path === "about") {
      return <Header />;
    }
    //if (path === "account-based-marketing" || path === "explore") {
    else {
      return <Header2 />;
    }
  }
}

export default withRouter(HeaderRouter);
