import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { getLanguage } from "../../util/localStorage";

import swiftForestOverview from "../../media/img/swift-finance-overview.jpg";
import slantedReport from "../../media/img/slanted-report.png";
import swiftLogo from "../../media/img/swift-finance-logo.png";
import ClientLogos from "../layouts/ClientLogos";
import { track } from "../../actions/trackingActions";

import Demo1 from "../../media/video/Demo1.mp4";
import Demo2 from "../../media/video/Demo2.mp4";
import Demo3 from "../../media/video/Demo3.mp4";
import Demo4 from "../../media/video/Demo4.mp4";
import Demo5 from "../../media/video/Demo5.mp4";

import TestimonialCards from "../layouts/TestimonialCards";
import Video from "../layouts/Video";

import OverviewVideo from "../../media/video/overview-swift.mp4";

import AOS from "aos";
import "aos/dist/aos.css";

const Home2 = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({ duration: 800 });
    AOS.refresh();

    window.onscroll = function () {
      window.onscroll = null;
      dispatch(track(3));
    };
  }, []);

  let languageStoredInLocalStorage = getLanguage();

  let content = {
    english: {
      hero: {
        title: "Is your finance department showing leadership?",
        subtitle: "or does it rather struggle to provide what is asked from it?",
      },
      generalDescription: "The modern finance department, equipped for efficiency with ease.",
      swiftForestOverview: [
        "No more manual errors - each amount remains attached to its label",
        "Define your models and formats just once",
        "Dynamic and flexible reporting",
        "(double click to break down your periods and your data)",
        "Your data manager: direct access to your GL accounts",
        "Create your own personalized totals to facilitate alternate groupings",
        "Create your own personalized accounts to facilitate their grouping.",
        "Relative reallocations: the flexibility to adjust forecasts in one click",
      ],
      overviewVideoSection: {
        title: "The art of doing so much more in a lot less time",
        listElements: [
          "Create a report directly from your GL",
          "Easily slice and dice a data element in your report to access more detail",
          "Simple budgets & forecasts ... even with several legal entities",
          "One-click budget and forecast updates",
          "And much more!",
        ],
      },
      socialProof: {
        title: "Join the movement",
        subtitle: "More than twenty companies and organizations have chosen to trust Swift Finance",
        testimonials: [
          "The underlying technology of Swift Finance enables rapid implementation of complex functionality, accelerated deployment to users, and facilitates decentralized data entry. All this at a very competitive price.",
          "It is refreshing to see developers that are actually listening to their customers! Say thanks to the team.",
          "I worked in a large company that used Essbase as a financial planning and reporting tool and found more functional depth and flexibility in the Excel Swift Finance add-in. No matter how complex or asymmetric the required report is, we can easily create it in Excel using Swift Finance.",
        ],
        ctaButton: "Consult the case studies",
      },
      groupedSections: {
        title: "A methodology based on speed of execution,",
        subtitle: "in a robust and flexible technological framework",
        videos: {
          title: [
            "Easily access all your accounts",
            "Flexibility at the heart of your finance department",
            "Organizations with complex structures",
            "Quick budgets and forecasts",
            "Readjust your numbers in real time",
          ],
          subtitle: [
            "your GL, directly in Excel",
            "easily handle and break down your reports",
            "are easily segmented with Swift Finance",
            "create your scenarios in seconds",
            "will allow you to move forward much faster",
          ],
        },
      },
      ctaSection: {
        title: "How does Swift Finance rank among the available FP&A solutions?",
        ctaButton: "Consult the comparison table",
      },
    },

    french: {
      hero: {
        title: "Votre département de finance fait-il preuve de leadership?",
        subtitle: "ou peine-t’il plutôt à fournir ce qu’on lui demande?",
      },
      generalDescription: "Le département de finance moderne, outillé pour l’efficacité en toute facilité.",
      swiftForestOverview: [
        "Fini les erreurs manuelles - chaque montant demeure attaché à son libellé",
        "Définissez vos modèles et formats une seule fois",
        "Création de rapports dynamiques et flexibles",
        "(double clic pour ventiler vos périodes et vos données)",
        "Votre gestionnaire de données: un accès direct à vos comptes de GL",
        "Certains rapports vous demandent de regrouper plusieurs comptes dans la même rubrique?",
        "Créez vos propres comptes personnalisés pour faciliter leur regroupement.",
        "Réallocations relatives: la flexbilité d'ajuster ses prévisions en un clic",
      ],
      overviewVideoSection: {
        title: "L’art de faire tellement plus en bien moins de temps",
        listElements: [
          "Créez un rapport directement depuis votre GL",
          "Manipulez et ventilez facilement vos rapports (drag and drop)",
          "Budgets & prévisions simples... même avec plusieurs entités légales",
          "Mises à jour des prévisions budgétaires en un clic",
          "Et bien plus encore!",
        ],
      },
      socialProof: {
        title: "Joignez-vous au mouvement",
        subtitle: "Plus d'une vingtaine d'entreprises et organisations ont choisi de faire confiance à Swift Finance",
        testimonials: [
          "La technologie sous-jacente Swift Finance permet la mise en œuvre rapide de fonctionnalités complexes, un déploiement accéléré auprès des utilisateurs et facilite la saisie décentralisée des données. Tout cela à un prix très compétitif.",
          "Il est rafraîchissant de voir que des développeurs de logiciels écoutent réellement leurs clients! Dites merci à l'équipe.",
          "J'ai travaillé dans une grande entreprise qui utilisait Essbase comme outil de planification et de reporting financier et je trouve plus de profondeur fonctionnelle et de flexibilité dans le complément Excel Swift Finance. Quelle que soit la complexité ou l'asymétrie du rapport requis, nous pouvons facilement le créer dans Excel à l'aide de Swift Finance.",
        ],
        ctaButton: "Consulter les études de cas",
      },
      groupedSections: {
        title: "Une méthodologie basée sur la rapidité d'exécution,",
        subtitle: "dans un cadre technologique robuste et flexible",
        videos: {
          title: [
            "Accédez facilement à tous vos comptes",
            "La flexibilité au coeur de votre département de finances",
            "Les organisations aux structures complexes",
            "Budgets et prévisions rapides",
            "Réajustez vos chiffres en temps réel",
          ],
          subtitle: [
            "votre GL, directement dans Excel",
            "manipulez et ventilez facilement vos rapports",
            "sont aisément segmentées avec Swift Finance",
            "créez vos scénarios en quelques secondes",
            "vous permettra d’avancer beaucoup plus rapidement",
          ],
        },
      },
      ctaSection: {
        title: "Comment se classe Swift Finance parmi les solutions FP&A disponibles?",
        ctaButton: "Consulter le tableau comparatif",
      },
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="home2-page">
      {/* --hero section */}

      <div className="hero2">
        <div>
          <h1>{content.hero.title}</h1>
          <span>{content.hero.subtitle}</span>
        </div>
      </div>

      <div>
        {/* --section 2 */}
        <div className="general-description">
          <div className="">
            <img src={swiftLogo} data-aos="fade-right" className="swift-logo" alt="" />
          </div>
          <h2 data-aos="fade-right">{content.generalDescription}</h2>
        </div>

        {/* section */}

        {/* <div className="swift-forest-overview">
          <img src={swiftForestOverview} className="swift-forest-overview" alt="" width="70%" />
        </div> */}

        <div className="swift-forest-overview">
          <div className="visual-container">
            <img src={slantedReport} />

            <div data-aos="fade-left" data-aos-easing="ease-in-back" data-aos-delay="500" className="floating-text ft-1">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[0]}
            </div>
            <div data-aos="fade-left" data-aos-delay="1000" className="floating-text ft-2">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[1]}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-3">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[2]}
              <br />
              {/* <span>{content.swiftForestOverview[3]}</span> */}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-4">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[4]}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-5">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[5]}
              <br />
              {/* <br /> {content.swiftForestOverview[6]} */}
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className="floating-text ft-6">
              <i class="fas fa-check-circle"></i>&nbsp;{content.swiftForestOverview[7]}
            </div>
          </div>
        </div>

        {/* Video overview section */}
        <div className="overview-video-section">
          <div data-aos="fade-right" className="">
            <h2>{content.overviewVideoSection.title}</h2>
            <ul>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[0]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[1]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[2]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[3]}
              </li>
              <li>
                {" "}
                <i class="fas fa-check-double"></i> {content.overviewVideoSection.listElements[4]}
              </li>
            </ul>
          </div>
          <Video videoSource={OverviewVideo} FlagPlay={13} Flag10Sec={14} Flag80Percent={15} videoName={"vid1"} />
        </div>

        {/* --section 3 */}
        <div className="social-proof">
          <div>
            <div>
              <h2>{content.socialProof.title}</h2>
              <span>{content.socialProof.subtitle}</span>
            </div>

            {/* <TestimonialCards /> */}

            {/* //testimonial cards */}

            <div className="testimonial-card">
              <div className="panel active">
                <h3 data-aos="fade-up">
                  <i class="fas fa-quote-left"></i>&nbsp; {content.socialProof.testimonials[0]}
                </h3>
              </div>
              <div className="panel">
                <h3 data-aos="fade-right">
                  <i class="fas fa-quote-left"></i>&nbsp; {content.socialProof.testimonials[1]}
                </h3>
              </div>
              <div className="panel">
                <h3 data-aos="fade-left">
                  <i class="fas fa-quote-left"></i>&nbsp; {content.socialProof.testimonials[2]}
                </h3>
              </div>
            </div>
          </div>
          <Link to="/etudes-de-cas" id="trackFlag3" className="btn btn-primary all-center" onClick={() => props.dispatch(track(4))}>
            {content.socialProof.ctaButton}
          </Link>
        </div>

        {/* --section 4 */}
        <div className="grouped-sections">
          <h2>
            {content.groupedSections.title}
            <br />
            <span className="text-small">{content.groupedSections.subtitle}</span>
            <br />
          </h2>
          <div className="video-section section5">
            <div className="benefit-title-box my-top">
              <h3>{content.groupedSections.videos.title[0]}</h3>
              <span className="text-small color">
                {content.groupedSections.videos.subtitle[0]}
                {/* Un nouveau standard pour un département de finance allégé */}
              </span>
            </div>

            <div className="overview-video-section">
              <Video videoSource={Demo1} FlagPlay={16} Flag10Sec={17} Flag80Percent={18} videoName={"vid3"} />
            </div>

            {/* <img src={placeholder} className='benefit-video' alt='' /> */}
          </div>
          <div className="video-section section5">
            <div className="benefit-title-box">
              <h3>{content.groupedSections.videos.title[1]}</h3>

              <span className="text-small color">{content.groupedSections.videos.subtitle[1]}</span>
            </div>
            <div className="overview-video-section">
              <Video videoSource={Demo2} FlagPlay={19} Flag10Sec={20} Flag80Percent={21} videoName={"vid4"} />
            </div>
          </div>
          <div className="video-section section5">
            <div className="benefit-title-box">
              {/* <h2>Aperçus et brouillons rapides</h2> */}
              <h3>{content.groupedSections.videos.title[2]}</h3>
              <span className="text-small color">{content.groupedSections.videos.subtitle[2]}</span>
            </div>
            <div className="overview-video-section">
              <Video videoSource={Demo3} FlagPlay={22} Flag10Sec={23} Flag80Percent={24} videoName={"vid5"} />
            </div>
          </div>
          <div className="video-section section5">
            <div className="benefit-title-box">
              {/* <h2>Aperçus et brouillons rapides</h2> */}
              <h3>{content.groupedSections.videos.title[3]}</h3>
              <span className="text-small color">{content.groupedSections.videos.subtitle[3]}</span>
            </div>
            <div className="overview-video-section">
              <Video videoSource={Demo4} FlagPlay={25} Flag10Sec={26} Flag80Percent={27} videoName={"vid6"} />
            </div>
          </div>
          <div className="video-section section5">
            <div className="benefit-title-box">
              {/* <h2>Aperçus et brouillons rapides</h2> */}
              <h3>{content.groupedSections.videos.title[4]}</h3>
              <span className="text-small color">{content.groupedSections.videos.subtitle[4]}</span>
            </div>
            <div className="overview-video-section">
              <Video videoSource={Demo5} FlagPlay={28} Flag10Sec={29} Flag80Percent={30} videoName={"vid7"} />
            </div>
          </div>
        </div>

        {/* --section 6 */}
        <div className="section-cta">
          <div className="text-center">
            <h3>{content.ctaSection.title}</h3>
            <Link to="/fonctionnalites" id="trackFlag5" className="btn btn-primary" onClick={() => props.dispatch(track(5))}>
              {content.ctaSection.ctaButton}
            </Link>
            {/* 
          <Link to='/request-a-demo' className='' onClick={() => props.dispatch(track(22))} >
            Request your Demo
          </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Home2);
