import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import trackingReducer from "./reducers/trackingReducer";
import pageVariants from "./config/pageVariants";
import uniqID from "./util/uniqID";
import axios from "axios";
import { setLanguage } from "./util/localStorage";

const makeInitialState = () => {
  let state = {
    ready: false,
    pageVariant: pageVariants[Math.floor(Math.random() * pageVariants.length)],
    alreadySent: new Array(31),
    origin: window.location.origin,
    trackingCode: "",
    backupCode: uniqID(),
    demoRequested: false,
    firstVisit: true,
  };

  if (window.location.pathname.lastIndexOf("/") > 0) {
    state.trackingCode = window.location.pathname.substr(window.location.pathname.lastIndexOf("/") + 1);
  } else if (window.location.pathname.length == 11) {
    state.trackingCode = window.location.pathname.substr(window.location.pathname.lastIndexOf("/") + 1);
  }

  if (state.trackingCode !== "") {
    state.firstVisit = false;
  }

  for (let i = 0; i < 31; i++) {
    state.alreadySent[i] = false;
  }

  return state;
};

const trackingReducerDefaultState = makeInitialState();

const middleware = [thunk];
const store = createStore(trackingReducer, trackingReducerDefaultState, composeWithDevTools(applyMiddleware(...middleware)));

if (trackingReducerDefaultState.trackingCode === "") {
  (async (store, state) => {
    const { data } = await axios.get(`/tracking/unknown/${state.pageVariant}/${state.backupCode}/${encodeURI(btoa(window.location.pathname))}`);
    if (data.errorCode === 1) {
      store.dispatch({ type: "unknown", errorCode: 1, pageVariant: data.pageVariant, trackingCode: data.trackingCode });
    } else {
      store.dispatch({ type: "unknown", errorCode: data.errorCode });
    }
  })(store, trackingReducerDefaultState);
} else {
  (async (store, state) => {
    const { data } = await axios.get(`/tracking/newSession/${state.trackingCode}`);

    if (data.errorCode === 0) {
      console.log("[store.js] my language is " + data.language);
      setLanguage(data.language);
      store.dispatch({ type: "newSession", language: data.language });
    } else {
      store.dispatch({ type: "none" });
    }
  })(store, trackingReducerDefaultState);
}

export default store;
