import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./styles/styles.scss";

import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import LanguageSelector from "./components/layouts/LanguageSelector";

import Home from "./components/pages/Home";
import Home2 from "./components/pages/Home2";
import Contact from "./components/pages/Contact";
import HeaderRouter from "./components/layouts/HeaderRouter";
import FeaturesComparison from "./components/pages/FeaturesComparison";
import Page2 from "./components/pages/Page2";
import Page3 from "./components/pages/Page3";
import ScrollToTop from "./components/utils/ScrollToTop";

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "English");

  let content = {
    english: {
      pathName: ["explore", "features", "case-studies"],
    },
    french: {
      pathName: ["découvrir", "fonctionnalites", "etudes-de-cas"],
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="App">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Router>
        {/* <ScrollToTop /> */}
        <HeaderRouter />
        {/* <LanguageSelector
          language={language}
          handleSetLanguage={(language) => {
            setLanguage(language);
            storeLanguageInLocalStorage(language);
          }}
        /> */}
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route path={`/${content.pathName[0]}`} component={Home2} /> */}
          <Route path="/explore" component={Home2} />
          <Route path="/fonctionnalites" component={Page2} />
          <Route path="/etudes-de-cas" component={Page3} />
          <Route path="/:trackingCode" component={Home} />

          {/* <Route path="/contact" component={Contact} /> */}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
