import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { track } from "../../actions/trackingActions";


// props: videoSource, FlagPlay, Flag10Sec, Flag80Percent, videoName
//-


const Video = (props) => {

  const dispatch = useDispatch();

  const {videoName = ""} =  props;

  function setVideoControls() {
    // Video
    const video = document.getElementById("video_"+videoName);

    // Buttons
    const playButton = document.getElementById("play-pause_"+videoName);
    const muteButton = document.getElementById("mute_"+videoName);
    const fullScreenButton = document.getElementById("full-screen_"+videoName);

    // Sliders
    const seekBar = document.getElementById("seek-bar_"+videoName);
    const volumeBar = document.getElementById("volume-bar_"+videoName);
    var videoPlaying = false;

    const updateSeek = () => {
      setTimeout(() => {
        if(videoPlaying)
        {
          let value = (100 / video.duration) * video.currentTime;
          seekBar.value = value;

          requestAnimationFrame(updateSeek);
        }
      }, 1000);
    }


// videoFlags

const Home2MinOverviewVideoFlags = {
  watched10Sec: false,
  watched80Percent: false
}


    // Event listener for the play/pause button
    playButton.addEventListener("click", function () {
      if (video.paused == true) {
        // Play the video
        video.play();
        videoPlaying = true;
        requestAnimationFrame(updateSeek);
        console.log(this.FlagPlay);
        dispatch(track(this.FlagPlay));

        // Update the button text to 'Pause'
        playButton.innerHTML = `<i class="fas fa-pause"></i>`;
      } else {
        // Pause the video
        video.pause();
        videoPlaying = false;

        // Update the button text to 'Play'
        playButton.innerHTML = `<i class="fas fa-play"></i>`;
      }
    }.bind(props));

    // Event listener for the mute button
    muteButton.addEventListener("click", function () {
      if (video.muted == false) {
        // Mute the video
        video.muted = true;

        // Update the button text
        muteButton.innerHTML = `<i class="fas fa-volume-mute"></i>`;
      } else {
        // Unmute the video
        video.muted = false;

        // Update the button text
        muteButton.innerHTML = `<i class="fas fa-volume-up"></i>`;
      }
    });

    // Event listener for the full-screen button
    fullScreenButton.addEventListener("click", function () {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen(); // Firefox
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen(); // Chrome and Safari
      }
    });

    // Event listener for the seek bar
    seekBar.addEventListener("change", function () {
      // Calculate the new time
      let time = video.duration * (seekBar.value / 100);

      video.currentTime = time;
    }
    // .bind(Home2MinOverviewVideoFlags)

    );

    // Update the seek bar as the video plays
    video.addEventListener("timeupdate", function () {
      // Calculate the slider value
      let value = (100 / video.duration) * video.currentTime;


      // Update the slider value
      //seekBar.value = value;
      
      // tracking
      if (!Home2MinOverviewVideoFlags.watched10Sec && video.currentTime > 10 ){
        Home2MinOverviewVideoFlags.watched10Sec=true
        dispatch(track(this.Flag10Sec))
      }


      if (!Home2MinOverviewVideoFlags.watched80Percent && value > 80 ){
        Home2MinOverviewVideoFlags.watched80Percent=true
        dispatch(track(this.Flag80Percent))
      }

    }.bind(props));

    
    // Pause the video when the slider handle is being dragged
    seekBar.addEventListener("mousedown", function () {
      video.pause();
      videoPlaying = false;
    });

    // Play the video when the slider handle is dropped
    seekBar.addEventListener("mouseup", function () {
      video.play();
      videoPlaying = true;
      requestAnimationFrame(updateSeek);
    });

    // Adjust video time when the slider handle is dropped
    seekBar.addEventListener("input", function () {
      seekBar.value = this.value

       // Update the button text to 'Pause'
       playButton.innerHTML = `<i class="fas fa-pause"></i>`;
       
      // video.play();
    });
  
    

    // Event listener for the volume bar
    volumeBar.addEventListener("change", function () {
      // Update the video volume
      video.volume = volumeBar.value;
    });
  }

  return (
    <div id="video-container">
      <video
        controls={false}
        // autostart
        // autoPlay
        src={props.videoSource}
        type="video/mp4"
        className="video-component"
        id={"video_"+videoName}
        //   onPlay={() => props.dispatch(track(6))}
        //   onTimeUpdate={() => {
        //     if (document.getElementById("trackFlag6").currentTime > 10) {
        //       props.dispatch(track(11));
        //       document.getElementById("trackFlag6").ontimeupdate = null;
        //     }
        //   }}
      />
      <div id="video-controls" className='video-controls'>
        <button type="button" id={"play-pause_"+videoName} className='play-pause'>
          <i className="fas fa-play"></i>
        </button>
        <input className='seek-bar' type="range" id={"seek-bar_"+videoName} value="0" onChange={()=>{}}/>
        <button className='mute' type="button" id={"mute_"+videoName}>
          <i className="fas fa-volume-mute"></i>
        </button>
        <input className='volume-bar' type="range" id={"volume-bar_"+videoName} min="0" max="1" step="0.1" value="1" onChange={()=>{}}/>
        <button className='fullscreen' type="button" id={"full-screen_"+videoName}>
          <i className="fas fa-expand-alt"></i>
        </button>
      </div>
      {useEffect(setVideoControls)}
    </div>
  );
};

export default Video;
