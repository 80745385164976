const uniqID = () => {
    let d = new Date();

    let y = d.getFullYear()-2000;
    y = y.toString(2);
    y = y.padStart(8,'0');

    let m = d.getMonth();
    m = m.toString(2);
    m = m.padStart(4,'0');

    let day = d.getDate();
    day = day.toString(2);
    day = day.padStart(5,'0');

    let h = d.getHours()*3600000;
    let min = d.getMinutes()*60000;
    let s = d.getSeconds()*1000;
    let mil = d.getMilliseconds();

    let t = (h+min+s+mil).toString(2);
    t = t.padStart(26,'0');

    let r = Math.floor(Math.random()*128);
    r = r.toString(2);
    r = r.padStart(7,'0');


    let uniqBinary = y+m+day+t+r;

    let indexMap = {
        "00000": 0,
        "00001": 1,
        "00010": 2,
        "00011": 3,
        "00100": 4,
        "00101": 5,
        "00110": 6,
        "00111": 7,
        "01000": 8,
        "01001": 9,
        "01010": 10,
        "01011": 11,
        "01100": 12,
        "01101": 13,
        "01110": 14,
        "01111": 15,
        "10000": 16,
        "10001": 17,
        "10010": 18,
        "10011": 19,
        "10100": 20,
        "10101": 21,
        "10110": 22,
        "10111": 23,
        "11000": 24,
        "11001": 25,
        "11010": 26,
        "11011": 27,
        "11100": 28,
        "11101": 29,
        "11110": 30,
        "11111": 31,
    }

    let letterMap = {
        0:"0",
        1:"1",
        2:"2",
        3:"3",
        4:"4",
        5:"5",
        6:"6",
        7:"7",
        8:"8",
        9:"9",
        10:"A",
        11:"B",
        12:"C",
        13:"D",
        14:"E",
        15:"G",
        16:"H",
        17:"J",
        18:"K",
        19:"M",
        20:"N",
        21:"O",
        22:"P",
        23:"R",
        24:"S",
        25:"T",
        26:"U",
        27:"V",
        28:"W",
        29:"X",
        30:"Y",
        31:"Z",
    };

    let lastChar = uniqBinary.substr(45,5);
    let offset = indexMap[lastChar];

    let uniqid = letterMap[indexMap[lastChar]];
    for(let i=0;i<uniqBinary.length-5;i+=5)
    {
        uniqid += letterMap[(indexMap[uniqBinary.substr(i,5)]+offset) % 32];
    }

    return uniqid;

}

export default uniqID;