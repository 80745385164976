import { useSelector } from "react-redux";

const TrackingWrapper = (props) => {

    const isReady = useSelector((state) => state.ready);

    return(
        <>
            {isReady === true ? props.children : ''}
        </>
    )
}

export default TrackingWrapper;