import React from "react";

const Footer = () => {
  let language = "English";
  localStorage.setItem("language", language);

  function storeLanguageInLocalStorage(language) {
    localStorage.setItem("language", language);
  }

  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      footerRights: "All rights reserved",
    },
    french: { footerRights: "Tous droits réservés" },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <footer>
      <span>&#169; Swift Finance - {content.footerRights}.</span>
    </footer>
  );
};

export default Footer;
