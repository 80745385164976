import React from "react";
import { connect } from "react-redux";
import swiftLogo from "../../media/img/swift-finance-logo.png";
import { Link } from "react-router-dom";
import { track } from "../../actions/trackingActions";

const Page2 = (props) => {
  let language = "English";
  localStorage.setItem("language", language);

  function storeLanguageInLocalStorage(language) {
    localStorage.setItem("language", language);
  }

  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      pageTitle: "Place flexibility at the heart of your financial processes",
      mainSection: {
        headings: ["Flexibility", "Autonomy & Speed", "Security & Compliance"],
        listElements: [
          "Flexible and accessible financial reporting",
          "Management of interco allocations",
          "CAPEX and depreciation management",
          "Consolidation of multiple charters of accounts",
          "Complete autonomy, without IT involvement",
          "Create reports in just a few clicks",
          "One-click relative reallocations to adjust your forecasts",
          "Keep your sensitive data on a secure server",
          "Reduce the number of manual operations (and say goodbye to manual errors)",
          "Quickly trace the source of your data and any applied transformations",
        ],
      },
      ctaSection: {
        title: "Want to learn how to maximize your experience with Swift Finance?",
        description: "Discover the success stories of 2 Quebec companies and the Montreal branch of a large American company",
        button: "Read success stories",
      },
    },
    french: {
      pageTitle: "Placer la flexibilité au coeur de ses processus financiers",
      mainSection: {
        headings: ["Flexibilité", "Autonomie & Rapidité", "Sécurité & Conformité"],
        listElements: [
          "Reporting financier flexible et accessible",
          "Gestion des allocations intercos",
          "Gestion des CAPEX et amortissements",
          "Consolidation de chartes de comptes multiples",
          "Autonomie complète, sans implication des TI",
          "Création de rapports en quelques clics",
          "Réallocations relatives en un clic pour ajuster vos prévisions",
          "Gardez vos données sensibles sur un serveur sécurisé",
          "Réduisez le nombre d’opérations manuelles (et dites adieu aux erreurs manuelles)",
          "Retracez rapidement la source de vos données et de toutes les transformations appliquées",
        ],
      },
      ctaSection: {
        title: "Vous désirez apprendre comment maximiser votre expérience avec Swift Finance?",
        description: "Découvrez les histoires à succès de 2 entreprises québecoises et de la filiale montréalaise d’une grande entreprise américaine",
        button: "Lire les histoires à succès",
      },
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="page2">
      <h2>{content.pageTitle}</h2>
      {/* <img src={swiftLogo} width='250px' alt='' /> */}

      {/* / Table 1 / */}
      <div className="comparison-table">
        <div className="grid-3-column-title">
          <p> </p>
        </div>
        <h3 className="color">{content.mainSection.headings[0]}</h3>
        <div className="grid-3">
          <ul className="">
            <li>
              {" "}
              <i class="fas fa-check-circle orange" />
              &nbsp; {content.mainSection.listElements[0]}
            </li>
            <li>
              {" "}
              <i class="fas fa-check-circle orange" />
              &nbsp; {content.mainSection.listElements[1]}
            </li>
            <li>
              {" "}
              <i class="fas fa-check-circle orange" />
              &nbsp; {content.mainSection.listElements[2]}
            </li>
            <li>
              {" "}
              <i class="fas fa-check-circle orange" />
              &nbsp; {content.mainSection.listElements[3]}
            </li>
          </ul>
          <div className="grid-3-wrapper"></div>
        </div>
      </div>

      {/* / Table 2 / */}

      <div className="comparison-table">
        <div className="grid-3-column-title">
          <p> </p>
        </div>
        <h3 className="color">{content.mainSection.headings[1]}</h3>
        <div className="grid-3">
          <ul className="">
            <li>
              {" "}
              <i class="fas fa-check-circle orange" />
              &nbsp;{content.mainSection.listElements[4]}
            </li>
            <li>
              {" "}
              <i class="fas fa-check-circle orange" /> &nbsp;{content.mainSection.listElements[5]}
            </li>
            <li>
              {" "}
              <i class="fas fa-check-circle orange" />
              &nbsp; {content.mainSection.listElements[6]}
            </li>
          </ul>
          <div className="grid-3-wrapper"></div>
        </div>
      </div>

      {/* / Table 3 / */}

      <div className="comparison-table">
        <div className="grid-3-column-title">
          <p> </p>
        </div>
        <h3 className="color">{content.mainSection.headings[2]}</h3>
        <div className="grid-3">
          <ul className="">
            <li>
              {" "}
              <i class="fas fa-check-circle orange" /> &nbsp;{content.mainSection.listElements[7]}
            </li>
            <li>
              <i class="fas fa-check-circle orange" /> &nbsp;{content.mainSection.listElements[8]}
            </li>
            <li>
              <i class="fas fa-check-circle orange" />
              &nbsp; {content.mainSection.listElements[0]}
            </li>
          </ul>
          <div className="grid-3-wrapper"></div>
        </div>
      </div>

      <div className="cta">
        <h3>{content.ctaSection.title}</h3>
        <span className="text-small">{content.ctaSection.description}</span>
        <br />
        <Link to="/etudes-de-cas" className="btn btn-primary" onClick={() => props.dispatch(track(6))}>
          {content.ctaSection.button}
        </Link>
      </div>
    </div>
  );
};

export default connect()(Page2);
