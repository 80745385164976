function setLanguage(language) {
  localStorage.setItem("language", language);
}

const getLanguage = () => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  if (languageStoredInLocalStorage == null) {
    localStorage.setItem("language", "English");
    return "English";
  }
  return languageStoredInLocalStorage;
};

export { setLanguage, getLanguage };
