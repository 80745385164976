import axios from 'axios';

const trackingReducer = (state = {}, action) => {

    switch(action.type)
    {
        case 'track':
            if(state.ready)
            {
                if(!state.alreadySent[action.flag])
                {
                    (async () => {
                        axios.get(`/tracking/track/${state.trackingCode}/${action.flag}`);
                    })();

                    let newArray = [...state.alreadySent];
                    newArray[action.flag] = true;

                    return {...state, alreadySent:newArray};
                }
                else
                {
                    return state;
                }
            }
            else
            {
                return state;
            }
            break;
        case 'newSession':
            return {...state, ready: true, language: action.language};
        case 'unknown':
            if(action.errorCode === 1)
            {
                return {...state, ready: true, pageVariant: action.pageVariant, trackingCode: action.trackingCode, firstVisit: false}
            }
            else
            {
                return {...state, ready: true, trackingCode: state.backupCode}
            }
        default:
            return state;
    }
}

export default trackingReducer;