import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { getLanguage } from "../../util/localStorage";

const Header2 = () => {
  const [navShowing, toggleNav] = useState(false);

  const handleToggleNav = () => {
    toggleNav(!navShowing);
  };
  const closeNav = () => {
    toggleNav(!navShowing);
  };

  let languageStoredInLocalStorage = getLanguage();

  let content = {
    english: {
      navItems: ["Solution", "Features", "Case Studies"],
    },
    french: {
      navItems: ["Solution", "Fonctionnalités", "Études de cas"],
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <header>
      <button className="hamburger" onClick={handleToggleNav}>
        <i className="fas fa-bars" />
      </button>
      <NavLink to="/" className="logo-header" onClick={closeNav}></NavLink>
      <ul className={`${navShowing ? "show" : ""}`}>
        <NavLink to="/" activeClassName="underline" onClick={closeNav}>
          {content.navItems[0]}
        </NavLink>
        <NavLink to="/fonctionnalites" activeClassName="underline" onClick={closeNav}>
          {content.navItems[1]}
        </NavLink>
        <NavLink to="/etudes-de-cas" activeClassName="underline" onClick={closeNav}>
          {content.navItems[2]}
        </NavLink>
        {/* <NavLink to="/contact" onClick={closeNav}>
          Contact
        </NavLink> */}
      </ul>
    </header>
  );
};

export default Header2;
